import { ADD_LUCKY_DIPS, REMOVE_LUCKY_DIPS, UPDATE_LUCKY_DIP } from './action_types';

export const addLuckyDips = (luckyDips) => ({
  type: ADD_LUCKY_DIPS,
  payload: { luckyDips },
});

export const removeLuckyDips = (ids) => ({
  type: REMOVE_LUCKY_DIPS,
  payload: { ids },
});

export const updateLuckyDip = (luckyDip) => ({
  type: UPDATE_LUCKY_DIP,
  payload: { luckyDip },
});
