import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Skeleton } from 'bv-components';
import { loggedIn } from 'bv-helpers/session';
import { resetBetslip } from '../../lucky_dip_betslip/ducks/action_creators';
import { removeComponent } from '../ducks/components/action_creators';
import { makeSelectComponentById, makeSelectLuckyDips } from '../ducks/components/selectors';
import { fetchComponent } from '../ducks/components/thunks';
import Header from './header';
import LuckyDips from './lucky_dips';

const LuckyDipComponent = ({ componentId, eventId, isCasinoComponent }) => {
  const [loggedInOnMount] = useState(loggedIn());
  const selectComponentById = useMemo(makeSelectComponentById, []);
  const component = useSelector((state) => selectComponentById(state, componentId));
  const selectLuckyDips = useMemo(makeSelectLuckyDips, []);
  const luckyDips = useSelector((state) => selectLuckyDips(state, componentId));
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchComponent({
      componentId,
      eventId,
      isCasinoComponent,
    }));

    return () => dispatch(removeComponent(componentId));
  }, [componentId, eventId, isCasinoComponent]);

  useEffect(() => () => {
    if (loggedInOnMount === loggedIn()) {
      dispatch(resetBetslip());
    }
  }, []);

  if (!component || component.loading) {
    return eventId ? null : <Skeleton skeletonType="lucky-dip-wrapper" />;
  }
  if (!luckyDips.length) return null;

  return (
    <section className="lucky-dip-component">
      <Header {...component.header} />
      <LuckyDips
        luckyDips={luckyDips}
        showEventDescription={component.showEventDescription}
      />
    </section>
  );
};

LuckyDipComponent.propTypes = {
  componentId: PropTypes.number.isRequired,
  eventId: PropTypes.number,
  isCasinoComponent: PropTypes.bool,
};

LuckyDipComponent.defaultProps = {
  eventId: null,
  isCasinoComponent: false,
};

export default LuckyDipComponent;
