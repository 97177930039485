import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames';
import { assetPath, isDesktop, v as bvVar } from 'bv';
import { Spinner } from 'bv-components';
import { useLoggedIn } from 'bv-hooks';
import {
  get as getBalance,
  subscribe,
  unsubscribe,
} from 'bv-services/account-balance';
import { hasSportsbook } from 'global-config';
import { BonusSignPosting, PanelHandle } from 'sportsbook-betslip-components';
import { setSubmitResponse, showMiniBetslip } from 'sportsbook-betslip-duck';
import { showBetslip } from 'right-sidebar';
import { fetchLuckyDipSettings } from '../api';
import { resetBetslip as resetBetslipAction } from '../ducks/action_creators';
import { selectLuckyDip, selectSubmitResponse } from '../ducks/selectors';
import { submitBetslip } from '../ducks/thunks';
import LuckyDipAnimation from './animation';
import CloseButton from './close_button';
import Description from './description';
import GeneralError from './general_error';
import { isOpen } from '../helpers';
import LuckyDipStatus from './lucky_dip_status';
import Price from './price';
import PromocashToggle from './promocash_toggle';
import SubmitButton from './submit_button';
import StakeInput from './stake_input';

export default () => {
  const [balance, setBalance] = useState();
  const [mounted, setMounted] = useState(false);
  const [audio, setAudio] = useState(null);
  const loggedIn = useLoggedIn();
  const [luckyDipSettings, setLuckyDipSettings] = useState();
  const keyboardRef = useRef();
  const panelRef = useRef();
  const floatingNavNode = panelRef.current
    ? panelRef.current.parentNode
    : null;
  const luckyDip = useSelector(selectLuckyDip);
  const submitResponse = useSelector(selectSubmitResponse);
  const dispatch = useDispatch();

  const resetBetslip = () => dispatch(resetBetslipAction());
  const showBetReceipt = () => {
    resetBetslip();
    dispatch(setSubmitResponse({
      ...submitResponse.betPlacementResult,
      luckyDipBetReceipt: true,
    }));
    if (isDesktop() && hasSportsbook) {
      showBetslip();
    } else {
      dispatch(showMiniBetslip());
    }
  };

  useEffect(() => {
    if (loggedIn) {
      if (luckyDip?.id) {
        fetchLuckyDipSettings(luckyDip.id).then(setLuckyDipSettings);
      } else {
        setLuckyDipSettings(null);
      }
    }
  }, [loggedIn, luckyDip?.id]);

  useEffect(() => {
    setMounted(true);
    return resetBetslip;
  }, []);

  useEffect(() => {
    if (!loggedIn) return undefined;
    getBalance().then(setBalance);
    subscribe(setBalance);

    return () => { unsubscribe(setBalance); };
  }, [loggedIn]);

  const loadAnimationAudio = () => {
    const audioElement = new Audio(assetPath(bvVar('luckyDipAssets').audio));
    audioElement.load();
    audioElement.addEventListener('canplaythrough', () => {
      setAudio(audioElement);
    });
  };

  const stopAudio = () => {
    audio.pause();
    audio.src = '';
  };

  const handleSubmit = () => {
    dispatch(submitBetslip());
    loadAnimationAudio();
  };

  const bonusFundsEligible = loggedIn && balance?.decimalBonusBalance > 0
    && luckyDipSettings?.bonusFundsEligible;

  return (
    <>
      <div
        className={classnames('betslip betslip--mini betslip--lucky-dip', { 'is-open': !!luckyDip })}
        ref={panelRef}
      >
        {mounted && <PanelHandle hidePanel={resetBetslip} panelNode={floatingNavNode} animateProp="transform" />}
        <div>
          {loggedIn && luckyDip && !luckyDipSettings && <Spinner />}
          {luckyDip && (!loggedIn || luckyDipSettings) && (
            <>
              <GeneralError />
              <div className="betslip__bet-list">
                <div className={classnames('betslip-single', { 'bet--suspended': !isOpen(luckyDip) })}>
                  <LuckyDipStatus luckyDip={luckyDip} />
                  <CloseButton onClick={resetBetslip} />
                  <div className="betslip-single__container">
                    <Description
                      description={luckyDip.description}
                      eventDescription={luckyDip.eventDescription}
                      periodDescription={luckyDip.periodDescription}
                      promotionName={luckyDip.promotionName}
                    />
                    <div className="betslip-single__bet-info">
                      <div className="odds-input-wrapper">
                        <Price formattedPrice={luckyDip.formattedPrice} />
                        <StakeInput keyboardRef={keyboardRef} />
                      </div>
                      {bonusFundsEligible && (
                        <div className="extra-fields">
                          <BonusSignPosting />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <PromocashToggle bonusFundsEligible={bonusFundsEligible} />
              <SubmitButton
                balance={balance}
                maxStake={luckyDipSettings?.maxStake}
                onSubmit={handleSubmit}
              />
              <div className="betslip__keypad-container" ref={keyboardRef} />
            </>
          )}
        </div>
      </div>
      {submitResponse?.success && (
        <LuckyDipAnimation
          audio={audio}
          formattedPrice={submitResponse.betPlacementResult.singles[0].formattedPrice}
          onClose={showBetReceipt}
          selections={submitResponse.selections}
          stopAudio={stopAudio}
        />
      )}
    </>
  );
};
